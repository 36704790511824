<!-- eslint-disable -->
<template>
  <div style="z-index:150;" id="container" class="hfeed wpbf-page">
    <!--START OF HEADER NAV BAR-->
    <header data-elementor-type="header" data-elementor-id="884"
      class="elementor elementor-884 elementor-location-header" data-elementor-settings="[]">
      <div class="elementor-section-wrap">
        <section
          class="has_eae_slider elementor-section elementor-top-section elementor-element elementor-element-92c4c8a elementor-section-full_width elementor-section-height-min-height elementor-section-content-middle elementor-section-stretched elementor-section-height-default elementor-section-items-middle"
          data-particle_enable="false" data-particle-mobile-disabled="false" data-id="92c4c8a"
          data-element_type="section"
          data-settings='{"stretch_section":"section-stretched","background_background":"classic"}'>
          <div class="elementor-background-overlay"></div>

          <div class="elementor-container elementor-column-gap-no">
            <div
              class="has_eae_slider elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-a14ef18"
              data-id="a14ef18" data-element_type="column">
              <div class="elementor-widget-wrap elementor-element-populated">
                <section
                  class="has_eae_slider elementor-section elementor-inner-section elementor-element elementor-element-be61651 elementor-section-full_width elementor-section-content-middle elementor-section-height-default elementor-section-height-default"
                  data-particle_enable="false" data-particle-mobile-disabled="false" data-id="be61651"
                  data-element_type="section">
                  <div class="elementor-container elementor-column-gap-no">
                    <div
                      class="has_eae_slider elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-7c2f86c"
                      data-id="7c2f86c" data-element_type="column">
                      <div class="elementor-widget-wrap elementor-element-populated">
                        <div
                          class="elementor-element elementor-element-4e5afdb elementor-widget__width-auto elementor-view-default elementor-widget elementor-widget-icon"
                          data-id="4e5afdb" data-element_type="widget" data-widget_type="icon.default">
                          <div class="elementor-widget-container">
                            <div class="elementor-icon-wrapper">
                              <button @click="goBack" class="elementor-icon">
                                <i aria-hidden="true" class="fas fa-undo-alt"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="has_eaeß_slider elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-3f59545"
                      data-id="3f59545" data-element_type="column">
                      <div class="elementor-widget-wrap elementor-element-populated">
                        <div
                          class="elementor-element elementor-element-7c1de30 elementor-widget__width-inherit elementor-widget elementor-widget-image"
                          data-id="7c1de30" data-element_type="widget" data-widget_type="image.default">
                          <div class="elementor-widget-container">
                            <router-link :to="'/'">
                              <img width="518" height="268" :src="hotelData.hotelLogo" class="attachment-full size-full"
                                alt="" loading="lazy" sizes="(max-width: 518px) 100vw, 518px" />
                            </router-link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="has_eae_slider elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-6f118fe"
                      data-id="6f118fe" data-element_type="column">
                      <div class="elementor-widget-wrap elementor-element-populated">
                        <div
                          class="elementor-element elementor-element-dd205f2 elementor-widget__width-auto elementor-view-default elementor-widget elementor-widget-icon"
                          data-id="dd205f2" data-element_type="widget" data-widget_type="icon.default">
                          <div class="elementor-widget-container">
                            <div class="elementor-icon-wrapper">
                              <div class="elementor-icon-wrapper">
                                <button @click="openLanguageModal" class="elementor-icon">
                                  <i aria-hidden="true" class="fas fa-language"></i>
                                </button>
                              </div>
                              <router-link class="elementor-icon" :to="'/profile'">
                                <i aria-hidden="true" class="fas fa-user-circle"></i>
                              </router-link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
      </div>
    </header>
    <!--END OF HEADER NAV BAR-->
    <!-- Language Selection Modal -->

    <div id="showLanguageModal" class="modal">
      <div class="modal-content">
        <div class="container">
          <ul style="text-align:center">
            <li style="cursor: pointer" v-for="language in languages" :key="language.code"
              @click="selectLanguage(language.code)">
              <div class="langIcon">
                {{ language.name }}
                <!-- <img :src="language.flag" alt="language" style="width: 40px; height: 40px; margin-right: 5px;"> -->
              </div>
            </li>
          </ul>
          <div class="clearfix">
            <button type="button" style="width: 90%;margin: 0 auto;" class="cancelbtn" @click="cancelChangeLang()">
              <span style="color:red">( X )</span> Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BModal } from 'bootstrap-vue'

export default {
  name: "AppHeader",
  currentLanguage: "",
  browserLanguage: "",
  mainColor: "",
  components: {
    BModal
  },
  data() {
    return {
      hotelId: 1,
      showLanguageModal: false,
      baseURL: `${window.location.protocol}//${window.location.host}/`,
      hotelData: {
        hotelLogo: localStorage.getItem("hotelLogo"),
      },
      error: false,
      // languages: ['EN', 'AR', 'GR', 'FR', 'It'],
      languages: [
        { code: 'en', name: 'EN', flag: `${window.location.protocol}//${window.location.host}/` + '/images/languages/en.png' },
        { code: 'ar', name: 'AR', flag: `${window.location.protocol}//${window.location.host}/` + 'images/languages/ar.png' },
        { code: 'fr', name: 'DU', flag: `${window.location.protocol}//${window.location.host}/` + 'images/languages/fr.png' },
        { code: 'de', name: 'GR', flag: `${window.location.protocol}//${window.location.host}/` + 'images/languages/de.png' },
        { code: 'it', name: 'PO', flag: `${window.location.protocol}//${window.location.host}/` + 'images/languages/it.png' },
        { code: 'ru', name: 'RU', flag: `${window.location.protocol}//${window.location.host}/` + 'images/languages/ru.png' }
      ]

    };
  },
  created() {
    this.getHotelData();
    if (!localStorage.getItem("localization")) {
      let primaryLanguage = navigator.language.substr(0, 2).toLowerCase();

      // Map the primary language code to the specified languages
      switch (primaryLanguage) {
        case 'ar':
          this.browserLanguage = 'ar'; // Arabic
          break;
        case 'en':
          this.browserLanguage = 'en'; // English
          break;
        case 'it':
          this.browserLanguage = 'it'; // Italian
          break;
        case 'de':
          this.browserLanguage = 'de'; // German
          break;
        case 'fr':
          this.browserLanguage = 'fr'; // French
          break;
        case 'ru':
          this.browserLanguage = 'ru'; // Russian
          break;
        default:
          // Default to English if the browser language is not one of the specified languages
          this.browserLanguage = 'en'; // English
      }
      this.currentLanguage = this.browserLanguage;
      localStorage.setItem('localization', this.currentLanguage);
    }

  },
  methods: {
    getHotelData() {
      this.axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
          "api/hotel-data?api_key=" +
          process.env.VUE_APP_API_KEY +
          "&lang=" +
          process.env.VUE_APP_LANG +
          "&hotel_id=" +
          1
        )
        .then((res) => {
          console.log(res.data.data);
          this.hotelData.hotelLogo =
            process.env.VUE_APP_API_BASE_URL + res.data.data.logo;
          this.hotelData.hotelMainColor = res.data.data.main_color;
          this.hotelData.hotelSecondColor = res.data.data.second_color;
           // Store checkin_offset and checkout_offset in local storage
        localStorage.setItem('checkin_offset', res.data.data.checkin_offset);
        localStorage.setItem('checkout_offset', res.data.data.checkout_offset);
        
          this.$emit("hotel-data", this.hotelData);
          localStorage.setItem(
            "hotelLogo",
            process.env.VUE_APP_API_BASE_URL + res.data.data.logo
          );
        })
        .catch((error) => {
          console.log(error);
          this.error = true;
        });
    },
    setLocale(locale) {
      this.$i18n.locale = locale;
      localStorage.setItem('localization', locale);
      this.$router.push({
        params: { lang: locale }
      })
      location.reload()
    },
    goBack() {
      this.$router.go(-1)
    },
    openLanguageModal() {
      //this.showLanguageModal = true;
      document.getElementById("showLanguageModal").style.display = "block";

    }, cancelChangeLang() {
      document.getElementById("showLanguageModal").style.display = "none";
    },
    selectLanguage(language) {
      // Handle language selection here
      this.showLanguageModal = false; // Close the modal after selection
      this.setLocale(language); // Set locale based on selected language
    }

  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.language-modal .modal-content {
  background: rgba(0, 0, 0, 0.8);
  z-index: 50;
  /* Adjusted z-index */
}

.language-modal .modal-dialog {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.modal-content {
  width: 30%;
  border-radius: 20px;
}

.cancelbtn {
  background: #000000;
  color: #FFFFFF;
}
</style>
<!-- eslint-enable -->
