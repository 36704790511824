<!-- eslint-disable -->
<template>
  <div id="container" class="hfeed wpbf-page">
      <!-- pre loader -->
    <loader
      object="var( --e-global-color-primary)"
      v-if="isLoading"
      color1="#ffffff"
      color2="#999793"
      size="5"
      speed="2"
      bg="#343a40"
      objectbg="#999793"
      opacity="80"
      name="spinning"
    ></loader>
    <div
      data-elementor-type="wp-page"
      data-elementor-id="922"
      class="elementor elementor-922"
      data-elementor-settings="[]"
    >
      <div class="elementor-section-wrap">
        <!--Start of Background Slider-->

        <!--END of Background Slider-->
        <section
          class="has_eae_slider elementor-section elementor-top-section elementor-element elementor-section-content-middle elementor-section-height-full elementor-section-stretched elementor-section-boxed elementor-section-height-default elementor-section-items-middle"
          data-particle_enable="false"
          data-particle-mobile-disabled="false"
          data-id="09ca77d"
          data-element_type="section"
          data-settings='{"stretch_section":"section-stretched"}'
        >
          <div class="elementor-background-overlay"></div>
          <div class="elementor-container elementor-column-gap-no" style="margin-top: -60px;">
            <div
              class="has_eae_slider elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-f4d3b59"
              data-id="f4d3b59"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <section
                  class="has_eae_slider elementor-section elementor-inner-section elementor-element elementor-element-4ef0863 elementor-section-content-middle elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                  data-particle_enable="false"
                  data-particle-mobile-disabled="false"
                  data-id="4ef0863"
                  data-element_type="section"
                >
                  <div class="elementor-background-overlay"></div>
                  <div class="elementor-container elementor-column-gap-no" >
                    <div
                      class="has_eae_slider elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-15881f0"
                      data-id="15881f0"
                      data-element_type="column"
                    >
                      <div
                        class="elementor-widget-wrap elementor-element-populated"
                      >
                        <section
                          class="has_eae_slider elementor-section elementor-inner-section elementor-element elementor-element-bc57384 elementor-section-full_width elementor-section-content-middle elementor-section-height-default elementor-section-height-default"
                          data-particle_enable="false"
                          data-particle-mobile-disabled="false"
                          data-id="bc57384"
                          data-element_type="section"
                        >
                          <div
                            class="elementor-container elementor-column-gap-no"
                          >
                            <div
                              class="has_eae_slider elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-b63c29a"
                              data-id="b63c29a"
                              data-element_type="column"
                            >
                              <div
                                class="elementor-widget-wrap elementor-element-populated"
                              >
                                <div
                                  class="elementor-element elementor-element-0923c2c elementor-widget elementor-widget-image"
                                  data-id="0923c2c"
                                  data-element_type="widget"
                                  data-widget_type="image.default"
                                >
                                  <div class="elementor-widget-container">
                                    <img
                                      width="150"
                                      height="150"
                                      src="/images/rest.png"
                                      class="attachment-thumbnail size-thumbnail"
                                      alt=""
                                      loading="lazy"
                                      srcset="/images/rest.png"
                                      sizes="(max-width: 150px) 100vw, 150px"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              class="has_eae_slider elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-40bed3d"
                              data-id="40bed3d"
                              data-element_type="column"
                            >
                              <div
                                class="elementor-widget-wrap elementor-element-populated"
                              >
                                <div
                                  class="elementor-element elementor-element-bbd3d48 elementor-widget elementor-widget-heading"
                                  data-id="bbd3d48"
                                  data-element_type="widget"
                                  data-widget_type="heading.default"
                                >
                                  <div class="elementor-widget-container">
                                    <h2
                                      class="elementor-heading-title elementor-size-default"
                                    >
                                      {{lang.profile.welcome}}
                                    </h2>
                                  </div>
                                </div>
                                <div
                                  class="elementor-element elementor-element-da45d04 elementor-widget-mobile__width-auto elementor-widget elementor-widget-text-editor"
                                  data-id="da45d04"
                                  data-element_type="widget"
                                  data-widget_type="text-editor.default"
                                >
                                  <div class="elementor-widget-container">
                                    <p>{{ guestData.guestName }}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                        <section
                          class="has_eae_slider elementor-section elementor-inner-section elementor-element elementor-element-bdc8a60 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                          data-particle_enable="false"
                          data-particle-mobile-disabled="false"
                          data-id="bdc8a60"
                          data-element_type="section"
                        >
                          <div
                            class="elementor-container elementor-column-gap-no"
                          >
                            <div
                              class="has_eae_slider elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-cd2350f"
                              data-id="cd2350f"
                              data-element_type="column"
                            >
                              <div
                                class="elementor-widget-wrap elementor-element-populated"
                              >
                                <!--Start of Buttons-->
                                <div
                                  class="elementor-element elementor-element-c52092f elementor-align-center elementor-widget__width-auto elementor-mobile-align-justify elementor-widget elementor-widget-bdt-advanced-button"
                                  data-id="c52092f"
                                  data-element_type="widget"
                                  data-widget_type="bdt-advanced-button.default"
                                >
                                  <div class="elementor-widget-container">
                                    <div class="bdt-ep-button-wrapper">
                                      <router-link
                                        to="rate-us"
                                        class="bdt-ep-button bdt-ep-button-effect-a bdt-ep-button-size-sm elementor-animation-push"
                                      >
                                        <div
                                          class="bdt-ep-button-content-wrapper bdt-flex bdt-flex-middle bdt-flex-center"
                                          data-text="Review Us"
                                        >
                                          <div class="bdt-ep-button-text">
                                            <span class="avdbtn-text"
                                              >{{lang.profile.reviewUs}}</span
                                            >
                                          </div>
                                        </div>
                                      </router-link>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  class="elementor-element elementor-element-7069740 elementor-align-center elementor-widget__width-auto elementor-mobile-align-justify elementor-widget elementor-widget-bdt-advanced-button"
                                  data-id="7069740"
                                  data-element_type="widget"
                                  data-widget_type="bdt-advanced-button.default"
                                >
                                  <div class="elementor-widget-container">
                                    <div class="bdt-ep-button-wrapper">
                                      <router-link
                                        to="book"
                                        class="bdt-ep-button bdt-ep-button-effect-a bdt-ep-button-size-sm elementor-animation-push"
                                      >
                                        <div
                                          class="bdt-ep-button-content-wrapper bdt-flex bdt-flex-middle bdt-flex-center"
                                          data-text="Book A Restraunt"
                                        >
                                          <div class="bdt-ep-button-text">
                                            <span class="avdbtn-text"
                                              >{{lang.profile.bookARestraunt}}</span
                                            >
                                          </div>
                                        </div>
                                      </router-link>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  class="elementor-element elementor-element-25c8652 elementor-align-center elementor-widget__width-auto elementor-mobile-align-justify elementor-widget elementor-widget-bdt-advanced-button"
                                  data-id="25c8652"
                                  data-element_type="widget"
                                  data-widget_type="bdt-advanced-button.default"
                                >
                                  <div class="elementor-widget-container">
                                    <div class="bdt-ep-button-wrapper">
                                      <router-link
                                        to="booking-list"
                                        class="bdt-ep-button bdt-ep-button-effect-a bdt-ep-button-size-sm elementor-animation-push"
                                      >
                                        <div
                                          class="bdt-ep-button-content-wrapper bdt-flex bdt-flex-middle bdt-flex-center"
                                          data-text="Booking List"
                                        >
                                          <div class="bdt-ep-button-text">
                                            <span class="avdbtn-text"
                                              >{{lang.profile.bookingList}}</span
                                            >
                                          </div>
                                        </div>
                                      </router-link>
                                    </div>
                                  </div>
                                </div>
                                <!--End of Buttons-->
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                    <div
                      class="has_eae_slider elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-a13ef4c"
                      data-id="a13ef4c"
                      data-element_type="column"
                      data-settings='{"background_background":"classic"}'
                    >
                      <!--Start of Profile Info-->
                      <div
                        class="elementor-widget-wrap elementor-element-populated"
                      >
                        <div
                          class="elementor-element elementor-element-16a9d5a elementor-widget elementor-widget-heading"
                          data-id="16a9d5a"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          <div class="elementor-widget-container">
                            <h2
                              class="elementor-heading-title elementor-size-default"
                            >
                              {{lang.profile.name}}
                            </h2>
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-7e58599 elementor-widget elementor-widget-text-editor"
                          data-id="7e58599"
                          data-element_type="widget"
                          data-widget_type="text-editor.default"
                        >
                          <div class="elementor-widget-container">
                            <p>{{ guestData.guestName }}</p>
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-144a12f elementor-widget elementor-widget-heading"
                          data-id="144a12f"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          <div class="elementor-widget-container">
                            <h2
                              class="elementor-heading-title elementor-size-default"
                            >
                              {{lang.profile.room}}
                            </h2>
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-6358ec1 elementor-widget elementor-widget-text-editor"
                          data-id="6358ec1"
                          data-element_type="widget"
                          data-widget_type="text-editor.default"
                        >
                          <div class="elementor-widget-container">
                            <p>{{ guestData.guestRoom }}</p>
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-2cb67eb elementor-widget elementor-widget-heading"
                          data-id="2cb67eb"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          <div class="elementor-widget-container">
                            <h2
                              class="elementor-heading-title elementor-size-default"
                            >
                              {{lang.profile.reservation}}
                            </h2>
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-e184855 elementor-widget elementor-widget-text-editor"
                          data-id="e184855"
                          data-element_type="widget"
                          data-widget_type="text-editor.default"
                        >
                          <div class="elementor-widget-container">
                            <p>{{ guestData.guestReservation }}</p>
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-8fc4948 elementor-widget elementor-widget-heading"
                          data-id="8fc4948"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          <div class="elementor-widget-container">
                            <h2
                              class="elementor-heading-title elementor-size-default"
                            >
                              {{lang.profile.arrivalDate}}
                            </h2>
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-f5247b3 elementor-widget elementor-widget-text-editor"
                          data-id="f5247b3"
                          data-element_type="widget"
                          data-widget_type="text-editor.default"
                        >
                          <div class="elementor-widget-container">
                            <p>{{ guestData.guestArrivalData }}</p>
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-67b1363 elementor-widget elementor-widget-heading"
                          data-id="67b1363"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          <div class="elementor-widget-container">
                            <h2
                              class="elementor-heading-title elementor-size-default"
                            >
                              {{lang.profile.departureDate}}
                            </h2>
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-aa454c7 elementor-widget elementor-widget-text-editor"
                          data-id="aa454c7"
                          data-element_type="widget"
                          data-widget_type="text-editor.default"
                        >
                          <div class="elementor-widget-container">
                            <p>{{ guestData.guestDepartureDate }}</p>
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-41a9bf1 elementor-align-left elementor-widget elementor-widget-bdt-advanced-button"
                          data-id="41a9bf1"
                          data-element_type="widget"
                          data-widget_type="bdt-advanced-button.default"
                        >
                          <div class="elementor-widget-container">
                            <div class="bdt-ep-button-wrapper">
                              <a
                                @click="logout()"
                                class="bdt-ep-button bdt-ep-button-effect-a bdt-ep-button-size-sm elementor-animation-push"
                              >
                                <div
                                  class="bdt-ep-button-content-wrapper bdt-flex bdt-flex-middle bdt-flex-center"
                                  data-text="logout"
                                >
                                  <div class="bdt-ep-button-text">
                                    <span class="avdbtn-text">{{lang.profile.logOut}}</span>
                                  </div>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!--End of Profile Infor-->
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Profile",
  data() {
    return {
      guestData: {
        guestName: "",
        guestRoom: "",
        guestReservation: "",
        guestArrivalData: "",
        guestDepartureDate: "",
      },
      isLoading: true,
      lang:""

    };
  },
  created(){
    this.setLang();
        if(!User.loggedIn()){
      this.$router.push({name:'GuestLogin' });
        }
        this.axios
      .get(
        process.env.VUE_APP_API_BASE_URL +
          "api/user-profile?api_key=" +
          process.env.VUE_APP_API_KEY +
          "&lang=" +
          process.env.VUE_APP_LANG +
          "&api_token=" +
                 localStorage.getItem("token") +
          "&hotel_id=1&restaurant_id=1"
      )
      .then((res) => {
        this.guestData.guestName = res.data.data.first_name + ' ' + res.data.data.last_name
        this.guestData.guestRoom = res.data.data.more_data.room_number
        this.guestData.guestReservation = res.data.data.more_data.reservation
        this.guestData.guestArrivalData = res.data.data.more_data.checkin_date
        this.guestData.guestDepartureDate = res.data.data.more_data.checkout_date
        this.isLoading = false;


 
      })
      .catch((error) => console.log(error));
  },
  methods:{
    setLang() {
      this.axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            "api/frontend-keywords?api_key=" +
            process.env.VUE_APP_API_KEY +
            "&lang=" +
            localStorage.getItem("localization")
        )
        .then(
          (res) => {
            console.log(res)
            this.lang = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    logout(){
       localStorage.removeItem("token");
        localStorage.removeItem("user");
        //  this.$notify({
        //     group: "foo",
        //     title: "Success",
        //     text: "you have successfuly logged out ",
        //     type: "success",
        //   });
        this.$router.push({name:'GuestLogin'})
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  /* @media (max-width: 767px) {
    .elementor-922 .elementor-element.elementor-element-7e58599,    .elementor-922 .elementor-element.elementor-element-6358ec1 ,     .elementor-922 .elementor-element.elementor-element-e184855 , .elementor-922 .elementor-element.elementor-element-f5247b3 ,  {
        font-size: 0.8em;
    } 
}*/
</style>
<!-- eslint-enable -->
