var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"height":"100%"},style:(_vm.cssProps),attrs:{"id":"app"}},[_c('notifications',{staticClass:"setNot",attrs:{"group":"foo","position":"middle center"}}),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('AppHeader',{staticStyle:{"z-index":"150"}})],1),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('router-view',{staticStyle:{"z-index":"99"},on:{"hotel-data":_vm.updateHotel}})],1),_c('div',{staticClass:"slider-section",staticStyle:{"width":"100%","height":"100%","position":"absolute","top":"0px","background-color":"black"}},[_c('slider',{ref:"slider",attrs:{"options":_vm.options}},[_vm._l((_vm.someList),function(item,index){return _c('slideritem',{key:index,staticStyle:{"min-height":"300px","width":"100%"},style:({
  backgroundImage: 'url(' + _vm.imageBaseUrl + item.image + ')',
  backgroundSize: 'contain',  /* Ensure the image fits within the container */
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  height: '100%',
  width: '100%'
})})}),_c('div',{attrs:{"slot":"loading"},slot:"loading"},[_vm._v("loading...")])],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }