<!-- eslint-disable -->
<template>
  <div id="container" class="hfeed wpbf-page">
    <loader object="var( --e-global-color-primary)" v-if="isLoading" color1="#ffffff" color2="#999793" size="5"
      speed="2" bg="#343a40" objectbg="#999793" opacity="80" name="spinning"></loader>
    <!--START OF HEADER NAV BAR-->

    <!--END OF HEADER NAV BAR-->
    <div data-elementor-type="wp-page" data-elementor-id="3061" class="elementor elementor-3061"
      data-elementor-settings="[]">
      <div class="elementor-section-wrap">
        <!--START of BACKGROUND SLIDER-->

        <!--END of BACKGROUND SLIDER-->
        <section
          class="has_eae_slider elementor-section elementor-top-section elementor-element elementor-section-height-full elementor-section-content-middle elementor-section-stretched elementor-section-boxed elementor-section-height-default elementor-section-items-middle"
          data-particle_enable="false" data-particle-mobile-disabled="false" data-id="17739ff"
          data-element_type="section" data-settings='{"stretch_section":"section-stretched"}'>
          <div class="elementor-background-overlay"></div>
          <div class="elementor-container elementor-column-gap-no" style="margin-top: -60px">
            <div
              class="has_eae_slider elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-bca06f3"
              data-id="bca06f3" data-element_type="column">
              <div class="elementor-widget-wrap elementor-element-populated">
                <div class="elementor-element elementor-element-3369a30 elementor-widget elementor-widget-heading"
                  data-id="3369a30" data-element_type="widget" data-widget_type="heading.default">
                  <div class="elementor-widget-container">
                    <h2 class="elementor-heading-title elementor-size-default">
                      <!-- {{lang.availbleDays.theNumberOfPeople}} -->
                    </h2>
                  </div>
                  <!-- <input v-model="companionsNumber" type="number" style="width: 40vw;border-radius: 20px;" placeholder="Please enter the Number Of Companions"> -->
                </div>
                <div class="elementor-element elementor-element-3369a30 elementor-widget elementor-widget-heading"
                  data-id="3369a30" data-element_type="widget" data-widget_type="heading.default">
                  <div class="elementor-widget-container">
                    <h2 class="elementor-heading-title elementor-size-default">
                      {{ lang.availbleDays.availableDays }}
                    </h2>
                  </div>
                </div>
                <section
                  class="has_eae_slider elementor-section elementor-inner-section elementor-element elementor-section-full_width elementor-section-content-middle elementor-section-height-default elementor-section-height-default"
                  data-particle_enable="false" data-particle-mobile-disabled="false" data-id="b04b56b"
                  data-element_type="section">
                  <div class="elementor-background-overlay"></div>
                  <div class="elementor-container elementor-column-gap-no">
                    <div
                      class="has_eae_slider elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-ad8b52a"
                      data-id="ad8b52a" data-element_type="column">
                      <div class="elementor-widget-wrap elementor-element-populated">
                        <div
                          class="elementor-element elementor-element-55a9b29 elementor-widget__width-auto elementor-widget elementor-widget-template"
                          data-id="55a9b29" data-element_type="widget" data-widget_type="template.default"
                          v-for="resturantAvailableDay in resturantAvailableTimes" :key="resturantAvailableDay.date">
                          <div class="elementor-widget-container">
                            <div class="elementor-template">
                              <div data-elementor-type="single-page" data-elementor-id="3169"
                                class="elementor elementor-3169 post-3061 page type-page status-publish hentry wpbf-post"
                                data-elementor-settings="[]">
                                <div class="elementor-section-wrap">
                                  <div
                                    class="has_eae_slider elementor-section elementor-top-section elementor-element elementor-element-2c5cab5 elementor-section-content-top elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                    data-particle_enable="false" data-particle-mobile-disabled="false" data-id="2c5cab5"
                                    data-element_type="section">
                                    <div class="elementor-container elementor-column-gap-no">
                                      <div
                                        class="has_eae_slider elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-6df8897"
                                        data-id="6df8897" data-element_type="column"
                                        data-settings='{"background_background":"classic"}'>
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                          <section
                                            class="has_eae_slider elementor-section elementor-inner-section elementor-element elementor-element-0cc4f0f elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                            data-particle_enable="false" data-particle-mobile-disabled="false"
                                            data-id="0cc4f0f" data-element_type="section">
                                            <div>
                                              
                                              <div class="row">
                                                <div
                                                  class="has_eae_slider col-md-7 col-5 elementor-column elementor-col-50 elementor-inner-column elementor-element"
                                                  data-id="2ccde09" data-element_type="column">
                                                  <div class="elementor-widget-wrap elementor-element-populated">
                                                    <div
                                                      class="elementor-element elementor-element-567090e elementor-widget elementor-widget-heading"
                                                      data-id="567090e" data-element_type="widget"
                                                      data-widget_type="heading.default">
                                                      <div class="elementor-widget-container">
                                                        <h2 class="elementor-heading-title elementor-size-default"
                                                          style="font-size: 1.2rem;">
                                                          {{ resturantAvailableDay.day }}
                                                        </h2>

                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  class="has_eae_slider col-md-4 col-7 elementor-column elementor-col-50 elementor-inner-column elementor-element"
                                                  data-id="eb01e92" data-element_type="column">
                                                  <div class="elementor-widget-wrap elementor-element-populated">
                                                    <h4 class="">
                                                      {{ resturantAvailableDay.date }}
                                                    </h4>

                                                  </div>
                                                </div>

                                              </div>
                                              <div class="row">
                                                <div class="col-md-12 text-left">
                                                  <label class="available-seats mb-0">*{{$t('availbleDays.available_seats')}}</label>
                                                </div>
                                              </div>
                                              <div class="row">
                                                <div class="col-md-12">
                                                  <div
                                                    class="elementor-element elementor-element-7f63bd8 MAoptions elementor-widget elementor-widget-html"
                                                    data-id="7f63bd8" data-element_type="widget"
                                                    data-widget_type="html.default">
                                                    <div class="elementor-widget-container">
                                                      <ul style="
                                                          list-style-type: none;
                                                          margin: auto;
                                                          padding-left: 0px;
                                                          text-align: center;
                                                        ">
                                                        <li style="
                                                            display: inline-block;
                                                            margin-inline: 0.25em;
                                                          "
                                                          v-for="availableHour in resturantAvailableDay.availableHours"
                                                          :key="availableHour">
                                                          <div class="radio">
                                                            <input :id="availableHour.id" name="radio" type="radio" @click="
      setTimeAvail(
        availableHour.id,
        resturantAvailableDay.date
      )
      " />
                                                            <label :for="availableHour.id" class="radio-label p-2">
                                                              {{ availableHour.time }} <span class="available-seats">({{
      availableHour.available_seats }}) 
                                                              </span></label>
                                                          </div>
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </section>
                                          <section
                                            class="has_eae_slider elementor-section elementor-inner-section elementor-element elementor-element-52c8f58 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                            data-particle_enable="false" data-particle-mobile-disabled="false"
                                            data-id="52c8f58" data-element_type="section">
                                            <div class="elementor-container elementor-column-gap-default">
                                              <div
                                                class="has_eae_slider elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-fa6ec3a"
                                                data-id="fa6ec3a" data-element_type="column">
                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                  <div
                                                    class="elementor-element elementor-element-6af7df8 elementor-align-justify elementor-widget elementor-widget-button"
                                                    data-id="6af7df8" data-element_type="widget"
                                                    data-widget_type="button.default">
                                                    <div class="elementor-widget-container">
                                                      <div class="elementor-button-wrapper">
                                                        <button @click="
      checkOut(
        resturantAvailableDay.date
      )
      " class="elementor-button-link elementor-button elementor-size-xs" role="button">
                                                          <span class="elementor-button-content-wrapper">
                                                            <span class="elementor-button-text">{{
      lang
        .availbleDays
        .choose
                                                              }}</span>
                                                          </span>
                                                        </button>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </section>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <div id="error-modal" class="modal">
      <div class="modal-content confirmation-modal-content">
        <div class="container">
          <h2 class="confirm-title">{{$t('confirmation.invalidMessage')}}</h2>
          <p>{{  invalidMessage }}</p>

          <div class="clearfix">
            <button type="button" class="cancelbtn" @click="cancelConfirm()">
              {{lang.confirmation.cancel }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CheckAvailableDays",
  data() {
    return {
      hotelId: 1,
      restaurant_id: this.$route.params.restId,
      orderId: this.$route.params.orderId,
      userCheckIn: "",
      resturantAvailableTimes: [],
      guestData: [],
      selectedTime: 0,
      selectedAvailable: "",
      selectedDate: "",
      companionsNumber: "",
      isLoading: false,
      lang: "",
      invalidMessage: ""
    };
  },
  created() {
    if(!User.loggedIn()){
      this.$router.push({name:'GuestLogin' });
        }
    this.setLang();
    // this.getUserCheckinAvail();
    this.getGuestInfo();


  },
  methods: {
    getGuestInfo() {
      this.axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
          "api/user-profile?api_key=" +
          process.env.VUE_APP_API_KEY +
          "&lang=" +
          process.env.VUE_APP_LANG +
          "&api_token=" +
          localStorage.getItem("token") +
          "&hotel_id=1&restaurant_id=1"
      )
      .then((res) => {
		  //console.log(" ----------- Guest Info ---------");
		  //console.log(res);
        this.guestData.guestName = res.data.data.first_name + ' ' + res.data.data.last_name
        this.guestData.guestRoom = res.data.data.more_data.room_number
        this.guestData.guestReservation = res.data.data.more_data.reservation
        this.guestData.guestArrivalData = res.data.data.more_data.checkin_date
        this.guestData.guestDepartureDate = res.data.data.more_data.checkout_date
		this.guestData.companions = res.data.data.more_data.companions
        this.isLoading = false;
		this.getResturantAvailTime();


        })
        .catch((error) => console.log(error));
    },
    cancelConfirm() {
      document.getElementById("error-modal").style.display = "none";
    },
    setLang() {
      this.axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
          "api/frontend-keywords?api_key=" +
          process.env.VUE_APP_API_KEY +
          "&lang=" +
          //  this.$i18n.locale
          localStorage.getItem("localization")
        )
        .then((res) => {
          //console.log(res);
          this.lang = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getUserCheckinAvail() {
      if (!User.loggedIn()) {
        localStorage.setItem("redirected", this.$route.params.menuId);
        this.$router.push({ path: "/" + "login" });
        return;
      }
      this.axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
          "api/guest-available-dates?api_key=" +
          process.env.VUE_APP_API_KEY +
          "&lang=" +
          localStorage.getItem("localization") +
          "&api_token=" +
          localStorage.getItem("token")
        )
        .then((res) => {
          //console.log(res.data);
          this.userCheckIn = res.data;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    adjustRestaurantTimesWithOffsets() {
  
  let checkinOffset = parseInt(localStorage.getItem('checkin_offset')) || 0;
  let checkoutOffset = parseInt(localStorage.getItem('checkout_offset')) || 0;

  let departureDate = new Date(this.guestData.guestDepartureDate);
  let arrivalDate = new Date(this.guestData.guestArrivalData);

  // Apply offsets to arrival and departure dates
  departureDate.setDate(departureDate.getDate() + checkoutOffset);
  arrivalDate.setDate(arrivalDate.getDate() + checkinOffset);

  let todayDate = new Date();
  todayDate.setHours(0, 0, 0, 0); 

  // Adjust arrivalDate if it's before today
  if (arrivalDate < todayDate) {
    arrivalDate = todayDate;
  }

  // Filter and adjust the available times based on arrival and departure dates
  this.resturantAvailableTimes = this.resturantAvailableTimes.filter((time) => {
    let availableDate = new Date(time.date);
    return availableDate >= arrivalDate && availableDate <= departureDate;
  });
}
,
getResturantAvailTime() {
  this.axios
    .get(
      process.env.VUE_APP_API_BASE_URL +
      "api/restaurant-available-times?api_key=" +
      process.env.VUE_APP_API_KEY +
      "&lang=" +
      localStorage.getItem("localization") +
      "&api_token=" +
      localStorage.getItem("token") +
      "&restaurant_id=" +
      this.restaurant_id
    )
    .then((res) => {
      console.log(res.data);
      this.resturantAvailableTimes = res.data;

      // Call the helper function to adjust times based on checkin/checkout offsets
      this.adjustRestaurantTimesWithOffsets();

      this.sortedItems();
    })
    .catch((error) => {
      console.log(error);
      this.isLoading = false;
    });
}
,
    sortedItems: function () {
      this.resturantAvailableTimes.sort((a, b) => {
        return new Date(a.date) - new Date(b.date);
      });
      return this.items;
    },
    setTimeAvail(time, date) {
      this.selectedTime = time;
      this.selectedDate = date;
      //console.log(this.selectedTime);
      //console.log(this.selectedDate);
    },
    checkOut(date) {
      if (this.selectedDate == date) {
        // if (this.selectedDate == date && this.companionsNumber!="") {
        this.isLoading = true;

        var formData = {
          api_key: process.env.VUE_APP_API_KEY,
          //lang: process.env.VUE_APP_LANG,
          lang: localStorage.getItem("localization"),
          api_token: localStorage.getItem("token"),
          date: this.selectedDate,
          restaurant: this.restaurant_id,
          available_time_id: this.selectedTime,
          number_of_companions: this.guestData.companions,
        };

        this.axios
          .post(
            process.env.VUE_APP_API_BASE_URL + "api/restaurant-booking-setDate",
            formData
          )
          .then((res) => {
            this.isLoading = false;
            //console.log(res.data);
            if (res.data.status == "ERROR") {
              this.invalidMessage = res.data.message
              document.getElementById("error-modal").style.display = "block";
              // alert(res.data.message);
              // this.$notify({
              //   group: "foo",
              //   title: "Invalid",
              //   text: res.data.message,
              //   type: "error",
              // });
            } else {
              // this.$notify({
              //           group: "foo",
              //           title: res.data.message,
              //           text: res.data.message,
              //           type: "success",
              //         });
              let bookingID = res.data.booking_id;
              localStorage.setItem('currentBookingID', bookingID);

              this.$router.push({
                path: '/menu_' + this.restaurant_id,
                menuId: this.restaurant_id
              });
            }
          })
          .catch((error) => {
            this.isLoading = false;

            this.errors = error;
            this.invalidMessage = error
            document.getElementById("error-modal").style.display = "block";

            // this.$notify({
            //   group: "foo",
            //   title: "Invalid",
            //   text: "Something error in add to cart please check your inputs and try again ",
            //   type: "error",
            // });
            console.log(error);
          });
      } else {
        this.invalidMessage = "please check your data "
        document.getElementById("error-modal").style.display = "block";
        // this.$notify({
        //   group: "foo",
        //   title: "Invalid",
        //   text: "please check your data ",
        //   type: "error",
        // });
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.confirmation-modal-content {
  width: 50% !important;
}
@media(max-width:767px) {
  .confirmation-modal-content {
    width: 95% !important;
  }
}
</style>
<!-- eslint-enable -->
