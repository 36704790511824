<!-- RatingQuestion.vue -->
<template>
    <div v-if="question.type === 'rating'">
      <div class="rating-options">
        <label v-for="option in ratingOptions" :key="option.value" class="rating-label">
          <input
            type="radio"
            :name="'question-' + question.id"
            :value="option.value"
            v-model="answers[question.id].rate"
            required
          />
          {{ option.label }}
        </label>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "RatingQuestion",
    props: {
      question: {
        type: Object,
        required: true
      },
      answers: {
        type: Object,
        required: true
      },
      ratingOptions: {
        type: Array,
        required: true
      }
    }
  };
  </script>
  

  <style scoped>
 
  label{
    margin-left: 15px;
    font-size: 18px;
  }
  </style>