<!-- eslint-disable -->
<template>
  <div id="container" class="hfeed wpbf-page">
    <loader
      object="var( --e-global-color-primary)"
      v-if="isLoading"
      color1="#ffffff"
      color2="#999793"
      size="5"
      speed="2"
      bg="#343a40"
      objectbg="#999793"
      opacity="80"
      name="spinning"
    ></loader>
    <!--start OF HEADER NAV BAR-->

    <!--end OF HEADER NAV BAR-->
    <div
      data-elementor-type="wp-page"
      data-elementor-id="968"
      class="elementor elementor-968"
      data-elementor-settings="[]"
    >
      <div class="elementor-section-wrap">
        <!--START OF BACKGROUND SLIDER-->

        <!--END OF BACKGROUND SLIDER-->
        <section
          style=""
          class="has_eae_slider elementor-section elementor-top-section elementor-element elementor-section-content-middle elementor-section-height-min-height elementor-section-stretched elementor-section-boxed elementor-section-height-default elementor-section-items-middle"
          data-particle_enable="false"
          data-particle-mobile-disabled="false"
          data-id="09ca77d"
          data-element_type="section"
          data-settings='{"stretch_section":"section-stretched"}'
        >
          <div class="elementor-background-overlay"></div>
          <div class="elementor-container elementor-column-gap-no">
            <div
              class="has_eae_slider elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-f4d3b59"
              data-id="f4d3b59"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <div
                  class="elementor-element elementor-element-7be384e elementor-widget elementor-widget-heading"
                  data-id="7be384e"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div class="elementor-widget-container">
                    <h2 class="elementor-heading-title elementor-size-default">
                      {{ lang.bookingList.bookingList}}
                    </h2>
                  </div>
                </div>
                <section
                  class="has_eae_slider elementor-section elementor-inner-section elementor-element elementor-element-4ef0863 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-particle_enable="false"
                  data-particle-mobile-disabled="false"
                  data-id="4ef0863"
                  data-element_type="section"
                >
                  <div class="elementor-background-overlay"></div>
                  <div class="elementor-container elementor-column-gap-default">
                    <div
                      class="has_eae_slider elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-a13ef4c"
                      data-id="a13ef4c"
                      data-element_type="column"
                    >
                      <div
                        class="elementor-widget-wrap elementor-element-populated"
                      >
                        <!--START OF TABLE-->
                        <div
                          class="elementor-element elementor-element-4ecdb79 elementor-widget__width-auto elementor-widget elementor-widget-eae-data-table"
                          data-id="4ecdb79"
                          data-element_type="widget"
                          data-widget_type="eae-data-table.default"
                        >
                          <div class="elementor-widget-container">
                            <div class="eae-table-container">
                              <div class="eae-table-wrapper">
                                <div class="eae-table-wrap">
                                  <table
                                    class="eae-table"
                                    data-settings='{"sort":false,"search":false}'
                                  >
                                    <thead class="eae-table-head">
                                      <th
                                        class="eae-table__head_column elementor-repeater-item-6b9bd86"
                                      >
                                        <div
                                          class="eae-table__head__wrapper"
                                          style="flex-direction: row"
                                        >
                                          <div
                                            class="eae-table__head-column-wrapper"
                                          >
                                            <span class="eae-table__column-text"
                                              >{{ lang.bookingList.bookNo}}</span
                                            >
                                          </div>
                                        </div>
                                      </th>
                                      <th
                                        class="eae-table__head_column elementor-repeater-item-4ab8b2d"
                                      >
                                        <div
                                          class="eae-table__head__wrapper"
                                          style="flex-direction: row"
                                        >
                                          <div
                                            class="eae-table__head-column-wrapper"
                                          >
                                            <span class="eae-table__column-text"
                                              >{{ lang.bookingList.date}}</span
                                            >
                                          </div>
                                        </div>
                                      </th>
                                      <th
                                        class="eae-table__head_column elementor-repeater-item-da93c3f"
                                      >
                                        <div
                                          class="eae-table__head__wrapper"
                                          style="flex-direction: row"
                                        >
                                          <div
                                            class="eae-table__head-column-wrapper"
                                          >
                                            <span class="eae-table__column-text"
                                              >{{ lang.bookingList.date}}</span
                                            >
                                          </div>
                                        </div>
                                      </th>

                                      <th
                                        class="eae-table__head_column elementor-repeater-item-bad7d79"
                                      >
                                        <div
                                          class="eae-table__head__wrapper"
                                          style="flex-direction: row"
                                        >
                                          <div
                                            class="eae-table__head-column-wrapper"
                                          >
                                            <span class="eae-table__column-text"
                                              >{{ lang.bookingList.status}}</span
                                            >
                                          </div>
                                        </div>
                                      </th>
                                      <th
                                        class="eae-table__head_column elementor-repeater-item-bad7d79"
                                      >
                                        <div
                                          class="eae-table__head__wrapper"
                                          style="flex-direction: row"
                                        >
                                          <div
                                            class="eae-table__head-column-wrapper"
                                          >
                                            <span class="eae-table__column-text"
                                              >{{ lang.bookingList.action}}</span
                                            >
                                          </div>
                                        </div>
                                      </th>
                                    </thead>
                                    <tbody class="eae-table__body">
                                      <tr
                                        v-for="bookingList in bookingData"
                                        :key="bookingList.id"
                                      >
                                        <td
                                          class="eae-table__body_row_column elementor-repeater-item-078eb52"
                                          colspan="1"
                                          rowspan="1"
                                        >
                                          <div
                                            class="eae-table__body-column-wrapper"
                                          >
                                            <router-link
                                              :to="
                                                
                                                '/qr-print' +
                                                '/order_' +
                                                bookingList.id
                                              "
                                              class="eae-table__col-inner"
                                            >
                                              <span
                                                class="eae-table-body__text"
                                                >{{
                                                  bookingList.book_number
                                                }}</span
                                              >
                                            </router-link>
                                          </div>
                                        </td>
                                        <td
                                          class="eae-table__body_row_column elementor-repeater-item-68ed61e"
                                          colspan="1"
                                          rowspan="1"
                                        >
                                          <div
                                            class="eae-table__body-column-wrapper"
                                          >
                                            <div class="eae-table__col-inner">
                                              <span class="eae-table-body__text"
                                                >{{ bookingList.date }}
                                              </span>
                                            </div>
                                          </div>
                                        </td>
                                        <td
                                          class="eae-table__body_row_column elementor-repeater-item-f773dd3"
                                          colspan="1"
                                          rowspan="1"
                                        >
                                          <div
                                            class="eae-table__body-column-wrapper"
                                          >
                                            <div class="eae-table__col-inner">
                                              <span
                                                class="eae-table-body__text"
                                                >{{
                                                  bookingList.number_of_companions
                                                }}</span
                                              >
                                            </div>
                                          </div>
                                        </td>

                                        <td
                                          class="eae-table__body_row_column elementor-repeater-item-71f58c6"
                                          colspan="1"
                                          rowspan="1"
                                        >
                                          <div
                                            class="eae-table__body-column-wrapper"
                                          >
                                            <div class="eae-table__col-inner">
                                              <span
                                                class="eae-table-body__text"
                                                >{{ bookingList.status }}</span
                                              >
                                            </div>
                                          </div>
                                        </td>
                                        <td
                                          class="eae-table__body_row_column elementor-repeater-item-71f58c6"
                                          colspan="1"
                                          rowspan="1"
                                        >
                                          <div
                                            class="elementor-element elementor-element-ee500cb elementor-align-center elementor-widget__width-auto elementor-widget elementor-widget-bdt-advanced-button"
                                            data-id="ee500cb"
                                            data-element_type="widget"
                                            data-widget_type="bdt-advanced-button.default"
                                            v-if="bookingList.status == 'pending' || bookingList.status == 'accepted'"
                                          >
                                            <div
                                              class="elementor-widget-container"
                                            >
                                              <div
                                                class="bdt-ep-button-wrapper"
                                              >
                                                <a
                                                  href="#"
                                                  class="bdt-ep-button bdt-ep-button-effect-a bdt-ep-button-size-sm elementor-animation-push"
                                                  @click="deleteBooking(bookingList.id)"
                                                >
                                                  <div
                                                    class="bdt-ep-button-content-wrapper bdt-flex bdt-flex-middle bdt-flex-center"
                                                    data-text="Cancel"
                                                  >
                                                    <div
                                                      class="bdt-ep-button-text"
                                                    >
                                                      <span class="avdbtn-text"
                                                        >{{ lang.bookingList.cancel}}</span
                                                      >
                                                    </div>
                                                  </div>
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!--END OF TABLE-->
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    
    <div id="confirmation-modal" class="modal">
      <div class="modal-content confirmation-modal-content">
        <div class="container">
          <h2 class="confirm-title">{{ lang.confirmation.ConfirmationMessage}}</h2>
          <p><b>{{ lang.confirmation.deleteBooking}}</b></p>

          <div class="clearfix">
            <button type="button" class="cancelbtn" @click="cancelConfirm()">
               {{ lang.confirmation.cancel}}
            </button>
            <button
              type="button"
              class="confirmbtn"
              @click="confirmDelete()"
              data-id=""
            >
              {{ lang.confirmation.delete}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BookingList",
  data() {
    return {
      bookingData: "",
      isLoading: true,
	  lang: ""
    };
  },
  created() {
    this.getBookingList();
    this.setLang();
  },
  methods:{
	  setLang() {
      this.axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            "api/frontend-keywords?api_key=" +
            process.env.VUE_APP_API_KEY +
            "&lang=" +
            localStorage.getItem("localization")
        )
        .then((res) => {
          console.log(res);
          this.lang = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getBookingList(){
      this.axios
      .get(
        process.env.VUE_APP_API_BASE_URL +
          "api/booking-list?api_key=" +
          process.env.VUE_APP_API_KEY +
          "&lang=" +
          process.env.VUE_APP_LANG +
          "&api_token=" +
          localStorage.getItem("token")
      )
      .then((res) => {
        this.bookingData = res.data.data;
        this.isLoading = false;
      })
      .catch((error) => console.log(error));
    },
    deleteBooking(id){
      document.getElementById("confirmation-modal").style.display = "block";
      $(".confirmbtn").data("id", id).attr("data-id", id); //setter

    },
    cancelConfirm() {
      document.getElementById("confirmation-modal").style.display = "none";
    },
    confirmDelete() {
      var id = $(".confirmbtn").data("id"); //getter
      document.getElementById("confirmation-modal").style.display = "none";
      this.isLoading = true;

      var formData = {
        api_key: process.env.VUE_APP_API_KEY,
        lang: process.env.VUE_APP_LANG,
        hotel_id: 1,
        api_token: localStorage.getItem("token"),
        booking_id: id,
      };

      this.axios
        .post(process.env.VUE_APP_API_BASE_URL + "api/delete-restaurant-booking", formData)
        .then((res) => {
          if (res.data.status == "ERROR") {
            this.$notify({
              group: "foo",
              title: "Booking Deletion Error",
              text: res.data.message,
              type: "error",
            });
          }else{
             setTimeout(() => {}, 5000);
            this.getBookingList();
          }
         
          
          this.isLoading = false;
          console.log(res.data);
          
        })
        .catch((error) => {
          this.isLoading = false;

          this.errors = error;
          this.$notify({
            group: "foo",
            title: "Booking Deletion Error",
            text: "Something error in delete booking please check your inputs and try again ",
            type: "error",
          });
          console.log(error);
        });
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.confirmation-modal-content {
  width: 50% !important;
}
@media(max-width:767px) {
  .confirmation-modal-content {
    width: 95% !important;
  }
}
</style>
<!-- eslint-enable -->
