import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../components/Home.vue';
import BookRestaurant from '../components/BookRestaurant.vue';
import GuestLogin from '../components/GuestLogin.vue';
import Profile from '../components/Profile.vue';
import Menu from '../components/Menu.vue';
import MenuOnly from '../components/MenuOnly.vue';
import Checkout from '../components/Checkout.vue';
import AvailableDays from '../components/AvailableDays.vue';
import CheckAvailableDays from '../components/CheckAvailableDays.vue';
import BookingList from '../components/BookingList.vue';
import QrPrint from '../components/QrPrint.vue';
import BookingDetails from '../components/BookingDetails.vue';
import i18n from '../i18n';
import RateUs from '../components/RateUs.vue';
import TestSwiper from '../components/testswiper.vue';

Vue.use(VueRouter);

const routes = [
 // {
  //  path: '/',
  //  redirect: `/${i18n.locale}`,
 // },
 // {
  //  path: '/:lang',
  //  component: {
   //   render(c) {
    //    return c('router-view');
     // },
    //},
    //children: [
      {
        path: '',
        redirect: Home,
      },
      {
        path: '/',
        redirect: Home,
      },
      {
        path: '/',
        name: 'Home',
        component: Home,
      },
      {
        path: '/',
        name: 'Home',
        component: Home,
      },

      {
        path: '/book',
        name: 'BookRestaurant',
        component: BookRestaurant,
      },
      {
        path: '/login',
        name: 'GuestLogin',
        component: GuestLogin,
      },
      {
        path: '/profile',
        name: 'Profile',
        component: Profile,
      },
      {
        path: '/menu_:menuId',
        name: 'Menu',
        component: Menu,
      },
	  {
        path: '/menuOnly_:menuId',
        name: 'Menu',
        component: MenuOnly,
      },
      {
        path: '/checkout/rest_:restId/order_:orderId',
        name: 'Checkout',
        component: Checkout,
      },
      {
        path: '/available-days/rest_:restId/order_:orderId',
        name: 'AvailableDays',
        component: AvailableDays,
      },
	  {
        path: '/check-available-days/rest_:restId/',
        name: 'CheckAvailableDays',
        component: CheckAvailableDays,
      },
      {
        path: '/booking-list',
        name: 'BookingList',
        component: BookingList,
      },
      {
        path: '/qr-print/order_:orderId',
        name: 'QrPrint',
        component: QrPrint,
      },
      {
        path: '/booking-detail/book_:bookId',
        name: 'BookingDetails',
        component: BookingDetails,
      },
      {
        path: '/rate-us',
        name: 'RateUs',
        component: RateUs,
      },
      {
        path: '/test-swiper',
        name: 'TestSwiper',
        component: TestSwiper,
      },
 //   ],
//  },
];
const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  history: true,
  routes,
});

export default router;
