import { render, staticRenderFns } from "./BookRestaurant.vue?vue&type=template&id=51feb39c&scoped=true&"
import script from "./BookRestaurant.vue?vue&type=script&lang=js&"
export * from "./BookRestaurant.vue?vue&type=script&lang=js&"
import style0 from "./BookRestaurant.vue?vue&type=style&index=0&id=51feb39c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51feb39c",
  null
  
)

export default component.exports