<!-- eslint-disable -->
<template>
  <div id="container" class="hfeed wpbf-page">
    <!-- pre loader -->
    <loader
      object="var( --e-global-color-primary)"
      v-if="isLoading"
      color1="#ffffff"
      color2="#999793"
      size="5"
      speed="2"
      bg="#343a40"
      objectbg="#999793"
      opacity="80"
      name="spinning"
    ></loader>

    <div
      data-elementor-type="wp-page"
      data-elementor-id="2218"
      class="elementor elementor-2218"
      data-elementor-settings="[]"
    >
      <div class="elementor-section-wrap">
        <!--START of BACKGROUND SLIDER-->

        <!--END of BACKGROUND SLIDER-->
        <section
          class="has_eae_slider elementor-section elementor-top-section elementor-element elementor-element-7548f4f elementor-section-height-min-height elementor-section-content-middle elementor-section-stretched elementor-section-full_width elementor-section-height-default elementor-section-items-middle"
          data-particle_enable="false"
          data-particle-mobile-disabled="false"
          data-id="7548f4f"
          data-element_type="section"
          data-settings='{"stretch_section":"section-stretched"}'
        >
          <div class="elementor-background-overlay"></div>
          <div class="elementor-container elementor-column-gap-no">
            <div
              class="has_eae_slider elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-a717e6b"
              data-id="a717e6b"
              data-element_type="column"
            >
              <div class="elementor-widget-wrap elementor-element-populated">
                <section
                  class="has_eae_slider elementor-section elementor-inner-section elementor-element elementor-element-33b040c elementor-section-full_width elementor-section-content-middle elementor-section-height-default elementor-section-height-default"
                  data-particle_enable="false"
                  data-particle-mobile-disabled="false"
                  data-id="33b040c"
                  data-element_type="section"
                >
                  <div class="elementor-container elementor-column-gap-no">
                    <div
                      class="has_eae_slider elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-9460b54"
                      data-id="9460b54"
                      data-element_type="column"
                    >
                      <div
                        class="elementor-widget-wrap elementor-element-populated"
                      >
                        <div
                          class="elementor-element elementor-element-9f81db3 elementor-widget elementor-widget-template"
                          data-id="9f81db3"
                          data-element_type="widget"
                          data-widget_type="template.default"
                        >
                          <div class="elementor-widget-container">
                            <div class="elementor-template">
                              <div
                                data-elementor-type="page"
                                data-elementor-id="3436"
                                class="elementor elementor-3436"
                                data-elementor-settings="[]"
                              >
                                <div class="elementor-section-wrap">
                                  <section
                                    class="theplus-equal-height has_eae_slider elementor-section elementor-top-section elementor-element elementor-element-64d82d2 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                    data-tp-equal-height-loadded=".elementor-heading-title elementor-size-default"
                                    data-particle_enable="false"
                                    data-particle-mobile-disabled="false"
                                    data-id="64d82d2"
                                    data-element_type="section"
                                    data-settings='{"background_background":"classic"}'
                                  >
                                    <div
                                      class="elementor-container elementor-column-gap-no"
                                    >
                                      <div
                                        class="has_eae_slider elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-5f364aa"
                                        data-id="5f364aa"
                                        data-element_type="column"
                                      >
                                        <div
                                          class="elementor-widget-wrap elementor-element-populated"
                                        >
                                          <!--START of SINGLE RESTAURANT CARD (DOUBLICATE NEXT DIV TO ADD MORE RESTAURANTS)-->
                                          <div
                                            class="theplus-equal-height elementor-element elementor-element-41c4a22 elementor-widget__width-auto elementor-widget elementor-widget-template"
                                            data-tp-equal-height-loadded=".cardMaxWidth"
                                            data-id="41c4a22"
                                            data-element_type="widget"
                                            data-widget_type="template.default"
                                            v-for="hotelResturant in hotelResturantsData"
                                            :key="hotelResturant.id"
                                          >
                                            <div
                                              class="elementor-widget-container"
                                            >
                                              <div class="elementor-template">
                                                <div
                                                  data-elementor-type="page"
                                                  data-elementor-id="2223"
                                                  class="elementor elementor-2223"
                                                  data-elementor-settings="[]"
                                                >
                                                  <div
                                                    class="elementor-section-wrap"
                                                  >
                                                    <section
                                                      class="has_eae_slider elementor-section elementor-top-section elementor-element elementor-element-7a8c802 elementor-section-content-middle elementor-section-height-min-height elementor-section-boxed elementor-section-height-default elementor-section-items-middle"
                                                      data-particle_enable="false"
                                                      data-particle-mobile-disabled="false"
                                                      data-id="7a8c802"
                                                      data-element_type="section"
                                                    >
                                                      <div
                                                        class="elementor-container elementor-column-gap-no"
                                                      >
                                                        <div
                                                          data-wts-url=""
                                                          data-wts-link="yes"
                                                          data-wts-new-window=""
                                                          class="has_eae_slider elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-344eb0b ae-link-yes Sheight"
                                                          data-id="344eb0b"
                                                          data-element_type="column"
                                                          data-settings='{"background_background":"classic"}'
                                                        >
                                                          <div
                                                            class="elementor-widget-wrap elementor-element-populated"
                                                            :style="
                                                              'background-image: url(' +
                                                              imageBaseUrl +
                                                              hotelResturant.thumbnail_image +
                                                              ');'
                                                            "
                                                            style="
                                                              background-position: center
                                                                center;
                                                              background-repeat: no-repeat;
                                                              background-size: cover;
                                                            "
                                                          >
                                                            <div
                                                              class="elementor-background-overlay"
                                                            ></div>
                                                            <div
                                                              class="elementor-element elementor-element-bbbd281 elementor-widget elementor-widget-image"
                                                              data-id="bbbd281"
                                                              data-element_type="widget"
                                                              data-widget_type="image.default"
                                                            >
                                                              <div
                                                                class="elementor-widget-container"
                                                              >
                                                                <router-link
                                                                  :to="{
                                                                    path:
                                                                     
                                                                      'menu_' +
                                                                      hotelResturant.restaurant_id,
                                                                  }"
                                                                >
                                                                  <img
                                                                    width="150"
                                                                    height="150"
                                                                    style="
                                                                      object-fit: cover;
                                                                    "
                                                                    :src="
                                                                      imageBaseUrl +
                                                                      hotelResturant.logo
                                                                    "
                                                                    class="attachment-thumbnail size-thumbnail"
                                                                    alt=""
                                                                    loading="lazy"
                                                                    sizes="(max-width: 150px) 100vw, 150px"
                                                                  />
                                                                </router-link>
                                                              </div>
                                                            </div>
                                                            <div
                                                              class="elementor-element elementor-element-a7da425 elementor-widget elementor-widget-heading"
                                                              data-id="a7da425"
                                                              data-element_type="widget"
                                                              data-widget_type="heading.default"
                                                            >
                                                              <div
                                                                class="elementor-widget-container"
                                                              >
                                                                <h1
                                                                  class="elementor-heading-title elementor-size-default"
                                                                >
                                                                  <a
                                                                    :to="{
                                                                      path:
                                                                         
                                                                        'menu_' +
                                                                        hotelResturant.restaurant_id,
                                                                    }"
                                                                    >{{
                                                                      hotelResturant.title
                                                                    }}</a
                                                                  >
                                                                </h1>
                                                              </div>
                                                            </div>
                                                            <div
                                                              class="elementor-element elementor-element-18e78e0 elementor-widget elementor-widget-heading"
                                                              data-id="18e78e0"
                                                              data-element_type="widget"
                                                              data-widget_type="heading.default"
                                                            >
                                                              <div
                                                                class="elementor-widget-container"
                                                              >
                                                                <h1
                                                                  class="elementor-heading-title elementor-size-default"
                                                                >
                                                                  {{
                                                                    hotelResturant
                                                                      .category
                                                                      .name
                                                                  }}
                                                                </h1>
                                                              </div>
                                                            </div>
                                                            <div
                                                              class="has_eae_slider elementor-section elementor-inner-section elementor-element elementor-element-3649651 elementor-section-content-middle elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                                                              data-particle_enable="false"
                                                              data-particle-mobile-disabled="false"
                                                              data-id="3649651"
                                                              data-element_type="section"
                                                            >
                                                              <div
                                                                class="elementor-container elementor-column-gap-no"
                                                              >
                                                                <div
                                                                  class="has_eae_slider elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-39ca5c2"
                                                                  data-id="39ca5c2"
                                                                  data-element_type="column"
                                                                >
                                                                  <div
                                                                    class="elementor-widget-wrap elementor-element-populated"
                                                                  >
                                                                    <div
                                                                      class="elementor-element elementor-element-93625f3 elementor-align-right elementor-widget__width-auto elementor-widget elementor-widget-bdt-advanced-button"
                                                                      data-id="93625f3"
                                                                      data-element_type="widget"
                                                                      data-widget_type="bdt-advanced-button.default"
                                                                    >
                                                                      <div
                                                                        class="elementor-widget-container"
                                                                      >
                                                                        <div
                                                                          class="bdt-ep-button-wrapper"
                                                                        >
                                                                          <router-link
                                                                            :to="{
                                                                              path:
                                                                            
                                                                                'check-available-days/rest_' +
                                                                                hotelResturant.restaurant_id,
                                                                            }"
                                                                            class="bdt-ep-button bdt-ep-button-effect-a bdt-ep-button-size-sm"
                                                                          >
                                                                            <div
                                                                              class="bdt-ep-button-content-wrapper bdt-flex bdt-flex-middle bdt-flex-center"
                                                                              data-text="Book Now"
                                                                            >
                                                                              <div
                                                                                class="bdt-ep-button-text"
                                                                              >
                                                                                <span
                                                                                  class="avdbtn-text"
                                                                                  >{{lang.book.bookNow}}</span
                                                                                >
                                                                              </div>
                                                                            </div>
                                                                          </router-link>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                    <div
                                                                      class="elementor-element elementor-element-472dc5d elementor-align-left elementor-widget__width-auto elementor-widget elementor-widget-bdt-advanced-button"
                                                                      data-id="472dc5d"
                                                                      data-element_type="widget"
                                                                      data-widget_type="bdt-advanced-button.default"
                                                                    >
                                                                      <div
                                                                        class="elementor-widget-container"
                                                                      >
                                                                        <div
                                                                          class="bdt-ep-button-wrapper"
                                                                        >
                                                                          <router-link
                                                                            :to="{
                                                                              path:
                                                                            
                                                                                'menuOnly_' +
                                                                                hotelResturant.restaurant_id,
                                                                            }"
                                                                            class="bdt-ep-button bdt-ep-button-effect-a bdt-ep-button-size-sm"
                                                                          >
                                                                            <div
                                                                              class="bdt-ep-button-content-wrapper bdt-flex bdt-flex-middle bdt-flex-center"
                                                                              data-text="Menu"
                                                                            >
                                                                              <div
                                                                                class="bdt-ep-button-text"
                                                                              >
                                                                                <span
                                                                                  class="avdbtn-text"
                                                                                  >{{lang.book.menu}}</span
                                                                                >
                                                                              </div>
                                                                            </div>
                                                                          </router-link>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </section>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <!--END of SINGLE RESTAURANT CARD (DOUBLICATE NEXT DIV TO ADD MORE RESTAURANTS)-->
                                        </div>
                                      </div>
                                    </div>
                                  </section>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BookRestaurant",
  data() {
    return {
      hotelId: 1,
      hotelResturantsData: [],
      imageBaseUrl: process.env.VUE_APP_API_BASE_URL,
      isLoading: true,
      lang:""
    };
  },

  created() {
    if (localStorage.getItem("redirected")) {
      var redirectTo = localStorage.getItem("redirected");
      localStorage.removeItem("redirected");
      //this.$router.push({ path: "menu_" + redirectTo });
    }
    this.axios
      .get(
        process.env.VUE_APP_API_BASE_URL +
          "api/hotel-restaurants?api_key=" +
          process.env.VUE_APP_API_KEY +
          "&lang=" +
          localStorage.getItem('localization')+
          //process.env.VUE_APP_LANG +
          "&hotel_id=1"
      )
      .then((res) => {
        this.hotelResturantsData = res.data.data;
        this.isLoading = false;
        console.log(res);
      })
      .catch((error) => console.log(error));
      this.setLang()
  },
  methods:{
    setLang() {
      this.axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            "api/frontend-keywords?api_key=" +
            process.env.VUE_APP_API_KEY +
            "&lang=" +
            localStorage.getItem("localization")
        )
        .then(
          (res) => {
            console.log(res)
            this.lang = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
<!-- eslint-enable -->
