<template>
  <div id="app" :style="cssProps" style="height:100%">

    <notifications class="setNot" group="foo" position="middle center" />

    <transition name="fade" mode="out-in">
      <AppHeader style="z-index:150;"></AppHeader>
    </transition>
    <transition name="fade" mode="out-in">
      <router-view @hotel-data="updateHotel" style="z-index:99;"></router-view>
    </transition>

    <div style="width:100%;height:100%;position:absolute;top:0px;background-color:black" class="slider-section">
      <!-- Slider component -->
      <slider ref="slider" :options="options">
        <!-- slideritem -->
        <slideritem v-for="(item, index) in someList" :key="index" :style="{
    backgroundImage: 'url(' + imageBaseUrl + item.image + ')',
    backgroundSize: 'contain',  /* Ensure the image fits within the container */
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    height: '100%',
    width: '100%'
  }" style="min-height: 300px; width: 100%;">
        </slideritem>
        <!-- Customizable loading indicator -->
        <div slot="loading">loading...</div>
      </slider>

    </div>


  </div>
</template>

<script>
import AppHeader from "./components/layout/AppHeader.vue";
import { slider, slideritem } from 'vue-concise-slider';
export default {
  name: "App",
  data() {
    return {
      hotelData: {
        hotelLogo: "",
        hotelMainColor: '',
        hotelSecondColor: '',
      },
      imageBaseUrl: process.env.VUE_APP_API_BASE_URL,
      someList: [

      ],
      // Slider configuration [obj]
      options: {
        currentPage: 0,
        effect: 'fade',
        thresholdDistance: 500,
        thresholdTime: 100,
        autoplay: 5000,
        loop: true,
        direction: 'vertical',
        loopedSlides: 1,
        slidesToScroll: 1,
        timingFunction: 'ease',
        speed: 3000
      }

    }
  },
  components: {
    AppHeader,
    slider,
    slideritem
  },
  created() {
    this.getHotelData()

    if (localStorage.getItem('hotel')) {
    }
    if (localStorage.getItem('localization') == null) {
      this.setLocale("en");
    }
  },
  computed: {
    cssProps() {
      return {
        '--e-global-color-primary': this.hotelData.hotelMainColor,
        '--e-global-color-secondary': this.hotelData.hotelSecondColor,
        '--e-global-color-gradient1': this.hotelData.hotelMainColor,
        '--e-global-color-gradient2': this.hotelData.hotelSecondColor,

      }
    }
  },
  methods: {
    updateHotel(hotel) {
      this.hotelData.hotelMainColor = hotel.hotelMainColor
      this.hotelData.hotelSecondColor = hotel.hotelSecondColor
    },
    getHotelData() {
      this.axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
          "api/hotel-data?api_key=" +
          process.env.VUE_APP_API_KEY +
          "&lang=" +
          process.env.VUE_APP_LANG +
          "&hotel_id=1"
        )
        .then((res) => {
          console.log(res.data.data);
          this.hotelData.hotelLogo =
            process.env.VUE_APP_API_BASE_URL + res.data.data.logo;
          this.hotelData.hotelMainColor = res.data.data.main_color;
          this.hotelData.hotelSecondColor = res.data.data.second_color;
          this.someList = res.data.data.slider

        })
        .catch((error) => {
          console.log(error);
          this.error = true;
        });
    }
  }

};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.elementor-kit-7 {
  --e-global-color-primary: #333a4b;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.setNot {
  width: 50%;
}

.notification-title {
  font-size: 1.5rem;
}

.notification-content {
  font-size: 1.25rem;
  padding: 10px;
}

@media screen and (max-width:767px) {
  .notification-title {
    font-size: 1.2rem;
  }

  .notification-content {
    font-size: 1.1rem;
  }

  .setNot {
    width: 100%;
  }
}
</style>
